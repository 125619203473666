var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("b", { staticClass: "secondary--text" }, [
          _vm._v("Exported applications"),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("v-spacer"),
              _c(
                "v-text-field",
                {
                  staticClass: "mr-2",
                  attrs: {
                    "prepend-inner-icon": "mdi-filter",
                    autofocus: "",
                    "hide-details": "",
                    solo: "",
                    flat: "",
                    "background-color": "grey lighten-4",
                    dense: "",
                    label: "Search for an exported application...",
                    clearable: "",
                  },
                  on: { "click:clear": _vm.removeSearchQuery },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", left: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { icon: "" } },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-cog")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c("v-list-item", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c("v-checkbox", {
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "secondary--text mx-3",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Include failed"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value: _vm.includeFailed,
                                                callback: function ($$v) {
                                                  _vm.includeFailed = $$v
                                                },
                                                expression: "includeFailed",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c("v-list-item", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c("v-checkbox", {
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "secondary--text mx-3",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Include deleted"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value: _vm.includeDeleted,
                                                callback: function ($$v) {
                                                  _vm.includeDeleted = $$v
                                                },
                                                expression: "includeDeleted",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  icon: "",
                                  loading: _vm.exportedImagesFetching,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$store.dispatch(
                                      "snapshotStore/fetchExportedImages",
                                      {
                                        snid: _vm.$route.params.snid,
                                        query: _vm.imageListQuery,
                                      }
                                    )
                                  },
                                },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("refresh")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Refresh exported applications")])]
              ),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              search: _vm.search,
              "item-key": "deid",
              headers: _vm.headers,
              items: _vm.exportedImages,
              options: _vm.tableOptions,
              loading: _vm.exportedImagesFetching,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions,
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function ({ options, pagination, updateOptions }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("v-spacer"),
                          _c("v-data-footer", {
                            staticClass: "no-border",
                            attrs: {
                              pagination: pagination,
                              options: options,
                              itemsPerPageOptions: _vm.itemsPerPageOptions,
                              "items-per-page-text":
                                "$vuetify.dataTable.itemsPerPageText",
                            },
                            on: { "update:options": updateOptions },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "no-data",
                  fn: function () {
                    return [
                      _vm._v(
                        "Currently this space has no exported environments."
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: `item.exit_code`,
                  fn: function ({ item }) {
                    return [
                      item.delete_timestamp
                        ? _c(
                            "v-chip",
                            { attrs: { small: "", color: "warning" } },
                            [_vm._v("Deleted")]
                          )
                        : !item.end_timestamp
                        ? _c(
                            "v-chip",
                            { attrs: { small: "", color: "primary" } },
                            [_vm._v("In progress")]
                          )
                        : item.exit_code === 0
                        ? _c(
                            "v-chip",
                            { attrs: { small: "", color: "success" } },
                            [_vm._v("Successful")]
                          )
                        : _c(
                            "v-chip",
                            { attrs: { small: "", color: "error" } },
                            [_vm._v("Failed")]
                          ),
                    ]
                  },
                },
                {
                  key: `item.app_name`,
                  fn: function ({ item }) {
                    return [_c("span", [_vm._v(_vm._s(item.app_name))])]
                  },
                },
                {
                  key: `item.app_description`,
                  fn: function ({ item }) {
                    return [_c("span", [_vm._v(_vm._s(item.app_description))])]
                  },
                },
                {
                  key: `item.end_timestamp`,
                  fn: function ({ item }) {
                    return [
                      item.end_timestamp
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateTimeToHuman")(item.end_timestamp)
                              )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.docker_url`,
                  fn: function ({ item }) {
                    return [
                      item.exit_code === 0
                        ? _c("span", [
                            _c("span", [_vm._v(_vm._s(item.docker_url))]),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.actions`,
                  fn: function ({ item }) {
                    return [
                      !item.delete_timestamp && item.exit_code === 0
                        ? [
                            _c("copy-to-clipboard", {
                              attrs: {
                                textToCopy: item.run_command,
                                buttonClass: "caption font-weight-bold",
                                buttonName: "copy to clipboard",
                                iconButton: true,
                                tooltip: "Copy run command to clipboard",
                              },
                            }),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    small: "",
                                                    color: "secondary",
                                                    loading:
                                                      _vm.deletingImage
                                                        ?.deid === item.deid &&
                                                      _vm.deleteRequestRunning,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openDeleteDialog(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("delete")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v("Delete exported application"),
                                ]),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }